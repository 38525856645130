* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.list {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0.5rem 0rem;
    background-color: #fff;
    color: black;
    font-family: 'Poppins', sans-serif;
  }
   /* Animations navbar */
    .nav__list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
    }

    .nav {
      display: flex;
      align-items: center;
      padding-left: 150px;
      padding-right: 150px;
    }


    nav.nav ul li a,
nav.fill ul li a {
  position: relative;
}
nav.nav ul li a:after,
nav.fill ul li a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: '.';
  color: transparent;
  background: #b98308;
  height: 1px;
}
nav.nav ul li a:hover:after {
  width: 100%;
}

nav.fill ul li a {
  transition: all 2s;
}

nav.fill ul li a:after {
  text-align: left;
  content: '.';
  margin: 0;
  opacity: 0;
}

nav ul li a:hover {
  color:#b98308;

}

nav.fill ul li a:hover {
  color: #b98308;
  z-index: 1;
}
nav.fill ul li a:hover:after {
  z-index: -10;
  animation: fill 1s forwards;
  -webkit-animation: fill 1s forwards;
  -moz-animation: fill 1s forwards;
  opacity: 1;
}
   /* Animations navbar */
    .logo {
      width: 200px;
      margin-top: 25px;
      margin-bottom: 20px;
    }
  
    .service {
      height: 100vh;
      background-color: gray;
    }
    
    .testimonal {
      height: auto;
      background-color: white;
    }
    
    .ServiceArea {
      height: auto;
      background-color: white;
      margin-top: 100px;
      margin-bottom: 100px;
    }
    
    
    .navbar {
      width: 100%;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    }
    
    .list {
      list-style: none;
      display: flex;
      margin-left: 80px;
    }
    
    .list-element {
      margin-right: 30px;
      margin-left: 20px;
      cursor: pointer;
    }

    .contact-us-button {
      /* float: left; */
      display: block;
      border: none;
      color: white;
      height: 40px;
      min-width: 100px;
      border-radius: 7px;
      background-color: #b98308;
      cursor: pointer;
      margin-right: 240px;
    }

    .logoresponsive {
      display: none;
    }

    .nav-btn {
      padding: 5px;
      cursor: pointer;
      background-color: transparent;
      border: none;
      outline: none;
      color:#b98308;
      visibility: hidden;
      opacity: 0;
      font-size: 3rem;
      display: none;
    }

    .logoresponsive {
      display: none;
    }


@media only screen and (max-width: 1024px){
  .nav-btn {
    visibility: visible;
    opacity:1;
    border: none;
    float: right;
    margin-top: 8px;
    margin-right: 10px;
    display: block;
  }

  .list {
    flex-direction: column;
    margin: 15px 0;
    justify-content: center;
    font-size: 35px;
  }

  .logoresponsive {
    display: inline;
    width: 250px;
    height: 70px;
    padding-left: 10px;
    padding-top: 10px;

  }

  .logo {
    position: absolute;
    top: 0.1rem;
    left: 2rem;
  }

  .ContactUs {
    display: none;
  }

  .contact-us-button {
   font-size: 1.5rem;
   top: 65%;
   margin-top: 60px;
   width: 100vh;
  }

  .nav{
    position: fixed;
    top: 0;
    left: 0;
    height: 70%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: white;
    transform: translateY(-100vh);
  }

  .responsive_nav{
    transform: none;
    cursor: pointer;
    top: 0;
    position: fixed;
  
  }

  .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav a{
    font-size: 1.5rem;
  }
}
