.header {
    height: 100vh;
    /* padding-left: 150px; */
    /* padding-right: 150px; */
    /* background-color: antiquewhite; */
    padding: 20px 20px;
  }

  .header-container {
    /* padding: 20px 20px; */
    max-width: 1960px;
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    padding-left: 150px;
    padding-right: 150px;
  }

  .header {
    /* padding-left: 150px; */
    /* padding-right: 150px; */
    height: 80vh;
    position: relative;
    display: flex;
    /* max-width: 84%; */
    margin: 0 auto;
    width: 50%;
  }

  .header-margin-top {
    margin-top: 100px;
  }

  .header-info {
    /* width: 60%; */
    padding-right: 15px;
  }

  .header-swiper-2 {
    height: 80vh;
    /* width: 1000px */
    /* margin-right: 200px; */
  }

  .header-text-brown {
    font-size: 60px;
    font-weight: 600;
    color: #b98308;
    margin-left: 20px;
  }
  
  .header-text {
    font-size: 60px;
    font-weight: 600;
  }
  
  .headet-text-p {
    font-size: 20px;
    width: 100%;
    /* margin-right: 200px; */
  }

  .swiper-image {
    width: 800px;
    height: 500px;
    height: auto;
    box-shadow: none;
  }

  .header-swiper {
    padding: 20px 20px;
    /* max-width: 1960px; */
    /* width: 100%; */
    box-sizing: border-box;
    margin: 0 auto;
    width: 40%;
  }

  /* .header-swiper {
    width: 80%;
  } */

  @media (max-width: 768px) {
    .header {
      height: 50vh;
      padding: 0;
      width: 100%;
    }

    .header-container {
      padding-left: 0px;
      padding-right: 0px;
      padding: 10px 10px;
      display: flex;
      flex-direction: column;
    }

    .header-margin-top {
      margin-top: 0px;
      display: flex;
    }

    .header-info {
      width: 100%; 
     padding-right: 0px;
    }

    .header-main-text {
      margin-top: 0px;
      display: flex;
      flex-direction: row;
    }

    .header-text {
      font-size: 20px;
      margin-right: 7px;
    }

    .header-text-brown {
      font-size: 20px;
      margin-left: 0px;
      margin-right: 7px;
    }

    .headet-text-p {
      font-size: 15px;
      width: 100%;
      margin-top: 10px;
    }

    .header-swiper {
      width: 100%;
      height: 250px;
    }

    .swiper-image {
      width: 100%;
    }
  }

/* 
  .logo {
    width: 200px;
    margin-top: 25px;
    margin-bottom: 20px;
  }

  .service {
    height: 100vh;
    background-color: gray;
  }
  
  .testimonal {
    height: 100vh;
    background-color: white;
  }
  
  .ContactUs {
    height: 100vh;
    background-color: gray;
  }
  
  .ServiceArea {
    height: 100vh;
    background-color: white;
  }
  
  
  .navbar {
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  }
  
  .list {
    list-style: none;
    display: flex;
    margin-left: 80px;
  }
  
  .list-element {
    margin-right: 30px;
    margin-left: 20px;
    cursor: pointer;
  } */
  
/*   .nav {
    display: flex;
    align-items: center;
    padding-left: 150px;
    padding-right: 150px;
  } */
  /* 
  .contact-us-button {
    display: block;
    margin-left: 40px;
    border: none;
    color: white;
    height: 40px;
    min-width: 100px;
    border-radius: 7px;
    background-color: #b98308;
    cursor: pointer;

  } */
/* ================= BREAKPOINTS =================== */
/*   @media (max-width 768px) {
    .header{
        top: initial;
        bottom: 0;
    }
    
    .nav {
        height: var(--header-height);
    }

    .nav__menu {
        position: fixed;
        bottom: -100%;
        left: 0;
        width: 100%;
        background-color: antiquewhite;
        padding: 2rem 1.5rem 4rem;
        box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
        border-radius: 1.5rem 1.5rem 0 0;
        transform: .3x;
    }
  } */

  /* Fro small devices */
/*   @media (max-width: 350px) {
    .nav__menu {
        padding: 2rem 0.25rem 4rem;
    }

    .nav__list {
        column-gap: 0;
    }
  } */