.container {
    padding-left: 150px;
    padding-right: 150px;
}

.slider-image {
    width: 250px;
}

.container-for{
    background-color: #F7F5EE;
    height: 100vh
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;600&display=swap');

/* @media only screen and (max-width: 600px)  {
    .container{
        width: 125vh;
    }
  } */
