body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.service {
  height: 100vh;
  background-color: #f7f5ee;
  width: 100%;
  margin-top: 20px;
}

.testimonal {
  /* height: 100vh; */
  background-color: white;
  width: 100%;
}

.ServiceArea {
  height: auto;
  background-color: #f7f5ee;
  width: 100%;
}

.ContactUs {
  height: 100vh;
  background-color: #f7f5ee;
  width: 100%;
}

/* .header {
  padding-left: 150px;
  padding-right: 150px;
  height: 80vh;
  display: flex;
} */

.logo {
  width: 200px;
  margin-top: 25px;
  margin-bottom: 20px;
}

.navbar {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.list {
  list-style: none;
  display: flex;
  margin-left: 80px;
}

.list-element {
  margin-right: 30px;
  margin-left: 30px;
  cursor: pointer;
}

.nav {
  display: flex;
  align-items: center;
  padding-left: 150px;
  padding-right: 150px;
}

.contact-us-button {
  margin-left: 250px;
  border: none;
  color: white;
  height: 40px;
  width: 140px;
  border-radius: 7px;
  background-color: #b98308;
  cursor: pointer;
}

/* .header-info {
  width: 50%;
} */



.experience {
  margin-top: 50px;
  display: flex;
  align-items: center;
}

.experince-years {
  text-align: center;
}

.margin-left {
  margin-left: 50px;
}

.experince-years-text {
  font-size: 40px;
  font-weight: 600;
}

.contact-us-button-in-header {
  margin-top: 40px;
  border: none;
  color: white;
  height: 40px;
  width: 140px;
  border-radius: 7px;
  background-color: #b98308;
  cursor: pointer;
}

.img-header{
  display: flex;
  flex-direction: column;
}

.hi {
  margin-top: 80px;

}

.header-images {
  display: flex;
  flex-direction: row;
  width: 50%;
 
}

.headers-image-numbers {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 30px;
}

.headers-images {
  width: 250px;
  height: 250px;
  border-radius: 15px;
}

.photo-popup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.prev-button,
.next-button,
.close-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;
}

.prev-button:hover,
.next-button:hover,
.close-button {
  background-color: #2980b9;
}

.thumbnail-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
}

.thumbnail {
  width: 50px;
  height: 50px;
  cursor: pointer;
  border: 2px solid transparent;
}

.thumbnail:hover {
  border-color: #3498db;
}

.thumbnail.active {
  border-color: #3498db;
}

.open-popup-button {
  width: 900px;
  height: 500px;
  margin-top: 20px;
  border-radius: 10px;
  cursor: pointer;
}

.service-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.our-recent-projects {
  margin-top: 70px;
  font-size: 70px;
}

.our-recent-projects-info {
  margin-top: 70px;
}

.span-width {
width: 50%;
}

.popup-div-images {
  display: flex;
  flex-direction: row;
  margin-top: 80px;
}

.popup-div-images2 {
  margin-top: 20px;
  border-radius: 10px;
  margin-left: 40px;
  height: 240px;
  width: 600px;
}

.right-divs {
  display: flex;
  flex-direction: column;
}

/* @media only screen and (max-width: 600px)  {
  .header-info{
    width: 100vh;
    padding-left: 150px;
    padding-right: 150px;
  }
} */