.testimonal {
    position: relative;
    /* padding: 20px 20px; */
    max-width: 1960px;
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    display: block;
    padding-left: 150px;
    padding-right: 150px;
}

.flooring-and-photos {
    display: flex;
    flex-direction: column;
}

.flooring {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
}

.flooring-materials {
    width: 30%;
    margin: 0 auto;
    font-size: 40px;
    font-weight: 600;
}

.our-legacy {
    width:40%;
    margin: 0 auto;
}

.photos {
    width: 85%;
    margin: 0 auto;
    margin-top: 80px;
    display: flex;
    flex-direction: row;
    gap: 60px;
}

.margin-auto {
    margin-top: 50px;
}


.photo-card {
    width: 250px;
    border: 1px solid black;
    border-radius: 15px;
    height: 370px;
    transition: all .5s;
    cursor: pointer;
    position: relative;
    transition: transform 0.3s, box-shadow 0.3s;
}

.photo-img:hover {
    transform: scale(1.1); 
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); 
}

/* .photo-img:hover::before {
    content: "";
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
} */

.photo-img {
    width: 250px;
    border-radius: 15px;
    position: absolute;
    align-items: center;
    justify-content: center;
    display: block;
    height: 370px;
}

.photo-label {
    position: relative;
    color: white;
    /* align-items: center; */
    /* justify-content: center; */
    top: 300px;
    left: 10%;
    font-size: 30px;
}

.photo-label:hover .shadow{
    box-shadow:0px -20px 40px rgba(35, 203, 167, 1) inset;
}

.cards-list {
    z-index: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .card {
    margin: 30px auto;
    width: 300px;
    height: 300px;
    border-radius: 40px;
  box-shadow: 5px 5px 30px 7px rgba(0,0,0,0.25), -5px -5px 30px 7px rgba(0,0,0,0.22);
    cursor: pointer;
    transition: 0.4s;
  }
  
  .card .card_image {
    width: inherit;
    height: inherit;
    border-radius: 40px;
  }
  
  .card .card_image img {
    width: inherit;
    height: inherit;
    border-radius: 40px;
    object-fit: cover;
  }
  
  .card .card_title {
    text-align: center;
    border-radius: 0px 0px 40px 40px;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 30px;
    margin-top: -80px;
    height: 40px;
  }
  
  .card:hover {
    transform: scale(0.9, 0.9);
    box-shadow: 5px 5px 30px 15px rgba(0,0,0,0.25), 
      -5px -5px 30px 15px rgba(0,0,0,0.22);
  }
  
  .title-white {
    color: white;
  }
  
  .title-black {
    color: black;
  }
  
  @media all and (max-width: 768px) {
    .card-list {
      /* On small screens, we are no longer using row direction but column */
      flex-direction: column;
    }
  }
  
  
  /*
  .card {
    margin: 30px auto;
    width: 300px;
    height: 300px;
    border-radius: 40px;
    background-image: url('https://i.redd.it/b3esnz5ra34y.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-repeat: no-repeat;
  box-shadow: 5px 5px 30px 7px rgba(0,0,0,0.25), -5px -5px 30px 7px rgba(0,0,0,0.22);
    transition: 0.4s;
  }
  */

/*  */


@media (max-width: 768px) {
    .testimonal {
        padding: 10px 10px;
        display: flex;
        flex-direction: column;
        height: 1700px;
        /* z-index: 1000; */
    }

    .flooring-and-photos {
        flex-direction: column;
    }

    .flooring {
        flex-direction: column;
        width: 100%;
        padding: 20px 20px;
        margin-top: 0px;
    }

    .flooring-materials {
        width: 100%;
        font-size: 30px;
        /* height: 150px; */
    }

    .our-legacy {
        width: 100%;
        margin-top: 20px;
    }

    .photos {
        width: 100%;
        padding: 20px 20px;
        /* display: flex; */
        flex-direction: column;
        /* justify-content: column; */
        gap: 0px;
        /* width: 50px; */
        /* height: 150px; */
        /* position: relative; */
        margin-top: 20px;
    }
    
    .photo-card {
        width: 100px;
        height: 150px;
    }

    .photo-img {
        width: 100px;
        height: 150px;
    }
}
