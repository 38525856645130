/* .responsive-container {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
} */

.all-container {
    position: relative;
    /* padding: 20px 20px; */
    max-width: 1960px;
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    display: block;
    padding-left: 150px;
    padding-right: 150px;
}

.slider-in-service {
    /* display: grid; */
    /* gap: 1rem; */
    /* width: 250px; */
    /* grid-template-columns: repeat(1, 1fr); */
    max-width: 84%;
    margin: 0 auto;
    display: flex;
    /* flex-wrap: wrap; */
    /* justify-content: space-between; */
    flex-direction: row;
    /* display: flex; */
    /* flex-direction: column; */
    margin-top: 100px;
    
}

.big-slide {
    width: 50%;
    /* height: 500px; */
    margin-right: 40px;
}

.second-slide {
    margin-top: 130px;
    /* margin-bottom: 50px; */
}

/* 
.big-slide-slide {
    height: 500px;
} */

.small-slides {
    width: 50%;
}

.text-and-slides {
    display: flex;
    flex-direction: column;
}

.service-text {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
}

.recent-project {
    width: 30%;
    margin: 0 auto;
    font-size: 40px;
    font-weight: 600;
}

.project-infos {
    width:40%;
    margin: 0 auto;
}

@media (max-width: 768px) {

    .all-container {
        padding-left: 0px;
        padding-right: 0px;
        padding: 10px 10px;
        display: flex;
        flex-direction: column;
        z-index: 1000;
    }

    .service-text {
        flex-direction: column;
        width: 100%;
        padding: 20px 20px;
        margin-top: 0px;
    }

    .recent-project {
        width: 100%;
        /* margin-top: -20px; */
        font-size: 30px;
    }

    .project-infos {
        width: 100%;
        margin-top: 20px;
    }

    .slider-in-service {
        flex-direction: column;
        margin-top: 10px;
    }

    .big-slide {
        width: 100%;
        margin-right: 0px;
    }

    .small-slides {
        width: 100%;
        margin-right: 0px;
    }

    .second-slide {
        margin-top: 20px;
    }

    .container-for {
        height: auto;
    }

    /* .responsive-container {
      padding: 10px;
    } */
}
  
/* @media (max-width: 480px) {
    .responsive-container {
      padding: 5px;
    }
} */